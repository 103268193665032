import fast from './images/fast.inline.svg';
import easy from './images/easy.inline.svg';
import transparent from './images/transparent.inline.svg';
import flexible from './images/flexible.inline.svg';
import config from '../../shared/config';

export const steps = [
  {
    icon: fast,
    title: 'Rápido',
    desc:
      'Créditos rápidos al instante: rellena la solicitud online y obtiene tu crédito en 10 minutos.',
  },
  {
    icon: easy,
    title: 'Sencillo',
    desc:
      'En QuéBueno puedes solicitar tu minicrédito online sin necesidad de presentar una nómina o un aval.',
  },
  {
    icon: transparent,
    title: 'Transparente',
    desc:
      'Podrás estar tranquilo ya que el importe total a devolver nunca cambia: es el que te indicamos desde el primer momento.',
  },
  {
    icon: flexible,
    title: 'Flexible',
    desc: `Puedes pedirnos hasta ${config.maxLoanAmount}€* durante un plazo máximo de 30 días, y si quieres ahorrar dinero, puedes anticipar tu pago cuando lo desees.`,
  },
];

export const queBueno = [
  {
    title: 'Flexibilidad',
    description:
      'En QuéBueno tienes flexibilidad. Al contrario que otros prestamistas, en QuéBueno puedes solicitar la cantidad exacta que necesites (hasta el último euro) y el número de días exactos que lo necesites. No te forzamos a elegir una cantidad que en realidad no necesites y, por tanto, pagar intereses sobre dinero que no necesitas. El importe del préstamo y el número de días afectarán el total a devolver así que puedes seleccionar la combinación que mejor se ajuste a tus necesidades en todos los aspectos.',
  },
  {
    title: 'Servicio transparente',
    description:
      'En QuéBueno somos transparentes. Te mostramos, por adelantado, el importe total que tendrás que devolver en la fecha acordada, siempre antes de que comiences a rellenar la solicitud. Detallamos el importe solicitado, los intereses y honorarios y el total a devolver para que quede totalmente claro. En ningún caso, los intereses son modificados a posteriori. Los únicos costes adicionales en los que puedes incurrir es si te retrasas en el pago.',
  },
  {
    title: 'Confianza',
    description: `En QuéBueno hacemos hincapié en construir una relación sólida y de confianza contigo. Al contrario de otros sitios webs, no llamamos tu atención con publicidad engañosa. En todo momento indicamos que la cantidad máxima que se puede solicitar son ${config.maxLoanAmount} euros para nuevos y hasta 600 euros para clientes responsables que ya han pagado algún otro préstamo con nosotros.`,
  },
  {
    title: 'Costes asociados',
    description:
      'QuéBueno es el prestamista más rápido y el más ágil en España. Se podría comparar con coger un taxi, no es el medio de transporte más económico si lo usas frecuentemente, pero merece la pena pagar un poco más ocasionalmente para poder llegar a tiempo a ciertas citas.',
  },
  {
    title: 'Clientes responsables',
    description:
      'En QuéBueno solicitamos al cliente a que utilice nuestro servicio de manera responsable. Animamos a que piense cuidadosamente antes de solicitar el préstamo. Es muy importante que verifique el importe total a devolver y la fecha de la devolución del préstamo para que ambas se adecúen a sus necesidades.',
  },
];
