import React from 'react';
import { pure } from 'recompose';
import Slider from 'react-slick';
import Typewriter from 'typewriter-effect';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { steps, queBueno } from './WhoWeAre.constants';
import workers from './images/workers.jpg';
import dottedPeople from './images/dotted-people.jpg';
import config from '../../shared/config';
import Step from '../Step';
import SliderArrow from '../SliderArrow';
import '../ContentPageLayout/ContentPageLayout.scss';
import './WhoWeAre.scss';

const WhoWeAre = () => {
  const stepsSliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const qbSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    swipeToSlide: true,
    prevArrow: <SliderArrow to="prev" />,
    nextArrow: <SliderArrow to="next" />,
  };

  return (
    <div className="WhoWeAre">
      <h1 className="ContentPageLayout-title">Quiénes somos</h1>
      <section className="WhoWeAre-top u-margin-top">
        <p className="WhoWeAre-intro">
          QuéBueno es un servicio gestionado por la empresa NBQ Technology, S.A.U., una sociedad
          constituida en el 2011 con oficinas centrales en Barcelona (Calle Jordi Girona 29, código
          postal 08034).{' '}
          <strong className="ContentPageLayout-strong">
            Nuestra misión es ofrecer productos financieros innovadores a través de internet usando
            el poder de la tecnología.
          </strong>
        </p>
        <LazyLoadImage
          wrapperClassName="WhoWeAre-img"
          src={workers}
          alt="Nosotros"
          loading="lazy"
        />
        <h2 className="ContentPageLayout-subTitle">¿A qué nos dedicamos?</h2>
        <p className="WhoWeAre-text">
          Te ayudamos ofreciéndote préstamos a corto plazo, de bajo importe y de forma simple,
          rápida y transparente, ingresándose el importe solicitado en la cuenta bancaria que nos
          indiques.
        </p>
      </section>
      <section className="WhoWeAre-steps">
        <Slider className="WhoWeAre-slider" {...stepsSliderSettings}>
          {steps.map(({ icon, title, desc }, index) => (
            <Step
              key={title}
              Icon={icon}
              title={title}
              desc={desc}
              showArrow={steps.length - 1 !== index}
            />
          ))}
        </Slider>
        <p className="WhoWeAre-star">
          * si ya eres cliente, podrás pedir hasta 900€. La cantidad concedida podrá variar según tu
          nivel de riesgo.
        </p>
      </section>
      <section className="WhoWeAre-financing">
        <h2 className="ContentPageLayout-subTitle">Financiación responsable</h2>
        <p className="WhoWeAre-text">
          Sólo ganamos dinero cuándo nos devuelves el préstamo en la fecha acordada. Por ello, no
          estamos interesados en concederte extensiones de plazo de manera constante.
          Adicionalmente, el importe máximo que se puede solicitar siempre es de{' '}
          {config.maxLoanAmount} euros para clientes nuevos y hasta 900 euros para clientes
          responsables que ya han pagado algún otro préstamo con nosotros.
        </p>
      </section>
      <section className="WhoWeAre-queBueno">
        <Slider {...qbSliderSettings}>
          {queBueno.map(({ title, description }) => (
            <div key={title} className="WhoWeAre-content">
              <h2 className="WhoWeAre-sectionName">!QUÉ BUENO!</h2>
              <h1 className="WhoWeAre-title">{title}</h1>
              <p className="WhoWeAre-sliderText u-margin-top">{description}</p>
            </div>
          ))}
        </Slider>
      </section>
      <section className="WhoWeAre-bottom">
        <div className="Grid">
          <div className="Grid-cell u-lg-size1of2 WhoWeAre-bottomLeft">
            <h2 className="WhoWeAre-heading2">
              QuéBueno es para ti,{' '}
              <Typewriter
                options={{
                  wrapperClassName: 'WhoWeAre-heading2 WhoWeAre-heading2--strong',
                  strings: [
                    'Jaime',
                    'María',
                    'Pedro',
                    'Cristina',
                    'David',
                    'Virginia',
                    'Jose',
                    'Daniela',
                    'Rubén',
                    'Carmen',
                    'Raúl',
                    'Laura',
                  ],
                  autoStart: true,
                  loop: true,
                }}
              />
            </h2>
            <div>
              <LazyLoadImage
                wrapperClassName="WhoWeAre-peopleImg"
                src={dottedPeople}
                alt="Personas"
              />
            </div>
          </div>
          <div className="Grid-cell u-lg-size1of2 WhoWeAre-bottomRight">
            <div className="WhoWeAre-block">
              <h3 className="WhoWeAre-heading3">QuéBueno No es un banco.</h3>
              <p>
                Se propone como un servicio de financiación cercano a sus clientes y que apoya las
                dificultades y las necesidades financieras de quienes confían en nuestra plataforma
              </p>
            </div>
            <div className="WhoWeAre-block">
              <h3 className="WhoWeAre-heading3">¿Tienes un gasto inesperado? ¡Qué Bueno!</h3>
              <p>
                Para Jaime que necesita dinero para reparar su coche; para María que necesita
                enfrentar los gastos de la mudanza; para Jose que necesita una ayuda para los gastos
                de autónomo
              </p>
            </div>
            <div className="WhoWeAre-block">
              <h3 className="WhoWeAre-heading3">¿Tienes un capricho? ¡Qué Bueno!</h3>
              <p>
                Para Raúl que necesita adelantar la nómina para irse de viaje; para Cristina que
                quiere hacer un curso de informática; para Virginia que quiere comprar una cámara
                nueva
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default pure(WhoWeAre);
